











import { Component, Vue } from 'vue-property-decorator'

import { ProfileWrapper } from '../../profile/molecules/ProfileWrapper'
import { BenefitPrograms } from '../organisms/BenefitPrograms'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<BenefitProgramsView>({
  name: 'BenefitProgramsView',
  components: {
    BenefitPrograms,
    ProfileWrapper
  }
})
export class BenefitProgramsView extends Vue {}

export default BenefitProgramsView
